import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import GatsbyLink from "../components/GatsbyLink";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import { PageHeader } from "../components/PageHeader";
import { RenderShortcode } from "../components/RenderShortcode";
import { ImagePass } from "../components/Image/ImagePass";
import "./post.scss";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";

export const ProjectPostTemplate = ({
  content,
  allCategories,
  categoryId,
  title,
  // author: { name: authorName },
  gridGallery = {},
  subheading,
  workAuthor,
}) => {
  const getCategory = (catNumber, allCategories) => {
    let name;
    allCategories.map((cat) => {
      if (cat.node.wordpress_id === catNumber) {
        name = cat.node.name;
        return;
      }
    });
    return name;
  };
  const [categoryName, setCategoryName] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const [imageToShow, setImageToShow] = useState("");

  const showImage = (currentImage) => {
    //set imageToShow to be the one that's been clicked on
    setImageToShow(currentImage);
    //set lightbox visibility to true
    setLightBoxDisplay(true);
  };

  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  useEffect(() => {
    setCategoryName(getCategory(categoryId[0], allCategories));
  }, []);

  return (
    <section className="single-post">
      <div className="single-post-wrap">
        <div className="col">
          <h2>{title}</h2>
          <p className="eyebrow">
            {subheading}
            {/* {workAuthor && <span className="author">By {workAuthor}</span>} */}
          </p>
        </div>
        <div className="col">
          <RenderShortcode content={content} />
        </div>
      </div>
      {lightboxDisplay && (
        <div id="lightbox" onClick={hideLightBox}>
          <div className="grid-gallery__item">
            <div className="image-container">
              <ImagePass src={imageToShow} id="lightbox-img" />
            </div>
          </div>
        </div>
      )}
      {gridGallery && (
        <div
          className={`grid-gallery${
            lightboxDisplay ? "  lightbox-open" : "  lightbox-closed"
          }`}
        >
          {Object.keys(gridGallery).length > 0 &&
            gridGallery?.map((gridItem, index) => {
              return (
                <div
                  className="grid-gallery__item"
                  key={index}
                  onClick={() => showImage(gridItem.image)}
                >
                  <div className="image-container">
                    <ImagePass src={gridItem?.image} />
                  </div>
                </div>
              );
            })}
        </div>
      )}
      <div className="more-link">
        <GatsbyLink to="/works/" className="button--dark">
          More work
        </GatsbyLink>
      </div>
    </section>
  );
};

const ProjectPost = (props) => {
  const { data, location } = props;
  const {
    wordpressPost: post,
    wordpressWpSettings,
    siteSettings,
    categories,
  } = data;

  const { wordpressUrl } = wordpressWpSettings;
  const { options = null } = siteSettings;
  if (!post) return null;
  const {
    title,
    content,
    excerpt,
    featured_media,
    yoast,
    date,
    project_category,
    acf,
  } = post;

  const { subheading, workAuthor, gridGallery } = acf;

  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={
          featured_media &&
          featured_media.source_url &&
          featured_media.source_url
        }
        location={location}
      />
      <PageHeader
        headerTitle={title}
        headerSubTitle={date}
        headerBackgroundImage={featured_media}
        location={location}
      />
      <ProjectPostTemplate
        content={content}
        categoryId={project_category}
        allCategories={categories?.edges}
        title={title}
        date={date}
        // author={author}
        blogSlug="projects"
        gridGallery={gridGallery}
        subheading={subheading}
        workAuthor={workAuthor}
      />
    </>
  );
};

export default Previewable(ProjectPost, "projects");

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProjectPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
        }
      }
    }
    wordpressPost: wordpressWpProjects(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      project_category
      yoast {
        metaTitle: title
        metaDescription: metadesc
        # opengraph_image {
        #   source_url
        # }
        # twitter_image {
        #   source_url
        # }
      }
      acf {
        subheading
        workAuthor
        gridGallery {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 830, quality: 70) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
